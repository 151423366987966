import * as _errCode2 from "err-code";

var _errCode = "default" in _errCode2 ? _errCode2.default : _errCode2;

import * as _fromString2 from "uint8arrays/from-string";

var _fromString = "default" in _fromString2 ? _fromString2.default : _fromString2;

var exports = {};
const errCode = _errCode;
const uint8ArrayFromString = _fromString;
/**
 * @typedef {import('../types').ChunkValidator} ChunkValidator
 */

/**
 * @type {ChunkValidator}
 */

async function* validateChunks(source) {
  for await (const content of source) {
    if (content.length === undefined) {
      throw errCode(new Error("Content was invalid"), "ERR_INVALID_CONTENT");
    }

    if (typeof content === "string" || content instanceof String) {
      yield uint8ArrayFromString(content.toString());
    } else if (Array.isArray(content)) {
      yield Uint8Array.from(content);
    } else if (content instanceof Uint8Array) {
      yield content;
    } else {
      throw errCode(new Error("Content was invalid"), "ERR_INVALID_CONTENT");
    }
  }
}

exports = validateChunks;
export default exports;
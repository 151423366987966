import * as _ipfsUnixfs2 from "ipfs-unixfs";

var _ipfsUnixfs = "default" in _ipfsUnixfs2 ? _ipfsUnixfs2.default : _ipfsUnixfs2;

import _persist from "../../utils/persist";
import * as _ipldDagPb2 from "ipld-dag-pb";

var _ipldDagPb = "default" in _ipldDagPb2 ? _ipldDagPb2.default : _ipldDagPb2;

var exports = {};
const {
  UnixFS
} = _ipfsUnixfs;
const persist = _persist;
const {
  DAGNode
} = _ipldDagPb;
/**
 * @typedef {import('../../types').BufferImporter} BufferImporter
 */

/**
 * @type {BufferImporter}
 */

async function* bufferImporter(file, block, options) {
  for await (let buffer of file.content) {
    yield async () => {
      options.progress(buffer.length, file.path);
      let unixfs;
      /** @type {import('../../types').PersistOptions} */

      const opts = {
        codec: "dag-pb",
        cidVersion: options.cidVersion,
        hashAlg: options.hashAlg,
        onlyHash: options.onlyHash
      };

      if (options.rawLeaves) {
        opts.codec = "raw";
        opts.cidVersion = 1;
      } else {
        unixfs = new UnixFS({
          type: options.leafType,
          data: buffer,
          mtime: file.mtime,
          mode: file.mode
        });
        buffer = new DAGNode(unixfs.marshal()).serialize();
      }

      return {
        cid: await persist(buffer, block, opts),
        unixfs,
        size: buffer.length
      };
    };
  }
}

exports = bufferImporter;
export default exports;
import * as _ipfsUnixfs2 from "ipfs-unixfs";

var _ipfsUnixfs = "default" in _ipfsUnixfs2 ? _ipfsUnixfs2.default : _ipfsUnixfs2;

import _persist from "../utils/persist";
import * as _ipldDagPb2 from "ipld-dag-pb";

var _ipldDagPb = "default" in _ipldDagPb2 ? _ipldDagPb2.default : _ipldDagPb2;

var exports = {};
const {
  UnixFS
} = _ipfsUnixfs;
const persist = _persist;
const {
  DAGNode
} = _ipldDagPb;
/**
 * @typedef {import('../types').Directory} Directory
 */

/**
 * @type {import('../types').UnixFSV1DagBuilder<Directory>}
 */

const dirBuilder = async (item, block, options) => {
  const unixfs = new UnixFS({
    type: "directory",
    mtime: item.mtime,
    mode: item.mode
  });
  const buffer = new DAGNode(unixfs.marshal()).serialize();
  const cid = await persist(buffer, block, options);
  const path = item.path;
  return {
    cid,
    path,
    unixfs,
    size: buffer.length
  };
};

exports = dirBuilder;
export default exports;
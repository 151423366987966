import * as _itAll2 from "it-all";

var _itAll = "default" in _itAll2 ? _itAll2.default : _itAll2;

var exports = {};
const all = _itAll;
/**
 * @type {import('../../types').FileDAGBuilder}
 */

exports = async function (source, reduce) {
  return reduce(await all(source));
};

export default exports;
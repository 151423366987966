import * as _itBatch2 from "it-batch";

var _itBatch = "default" in _itBatch2 ? _itBatch2.default : _itBatch2;

var exports = {};
const batch = _itBatch;
/**
 * @typedef {import('../../types').FileDAGBuilder} FileDAGBuilder
 */

/**
 * @type {FileDAGBuilder}
 */

function balanced(source, reduce, options) {
  return reduceToParents(source, reduce, options);
}
/**
 * @type {FileDAGBuilder}
 */


async function reduceToParents(source, reduce, options) {
  const roots = [];

  for await (const chunked of batch(source, options.maxChildrenPerNode)) {
    roots.push(await reduce(chunked));
  }

  if (roots.length > 1) {
    return reduceToParents(roots, reduce, options);
  }

  return roots[0];
}

exports = balanced;
export default exports;